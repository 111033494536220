.leadership-root {
  height: 100%;

  body {
    overflow: hidden;
    position: relative;
    background-color: #273550;
    min-width: 0;
    width: 2560px;
    height: 1660px;
    transform-origin: left top;
    left: 50%;
    top: 50%;
  }

  .root {
    height: 100%;
    width: 100%;
  }

  .ant-modal-content {
    transform: scale(1.8);
  }
}

.leadership-header {
  padding: 50px 150px;
}

.leadership-logo {
  display: flex;
  align-items: center;

  span {
    font-size: 52px;
    color: @white-color;
    font-weight: bold;
  }
}

.leander-time {
  font-size: 32px;
  color: @white-color;
  text-align: right;
}

.leander-info-state {
  margin-left: 24px;

  .leander-info-icon {
    width: 125px;
    height: 100px;
    background-color: #40a3e6;
    color: @white-color;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 60px;
    }
  }

  .leander-info-name {
    width: 125px;
    height: 35px;
    background-color: #01619e;
    color: @white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    .icon {
      font-size: 32px;
      margin-left: 10px;
    }
  }

  &.offline-state {
    .leander-info-icon {
      background-color: #94a0a8;
    }

    .leander-info-name {
      background-color: #818588;
    }
  }
}

.leadership-swiper-box {
  padding: 0 150px;
  --swiper-navigation-size: 54px;

  .swiper-button-prev,
  .swiper-button-next {
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #01619e;
    color: @white-color;
    border-radius: 50%;
    margin-top: 10px;

    &.end {
      opacity: 0.5;
    }
  }
}

.leadership-swiper {
  font-size: 44px;
  color: @white-color;
}

.leadership-left-item {
  background-color: #40a3e6;
  height: 100%;

  .ant-col {
    height: 100%;
  }
}

.ss-bg {
  height: 100%;
  background-color: #01619e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ss-bg1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #40a3e6;
}

.leadership-title {
  font-size: 48px;
  color: @white-color;
  position: relative;
  padding-left: 70px;

  span {
    font-size: 54px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 42px;
    border-radius: 20px;
    background-color: @white-color;
  }
}

.leadership-calendar {
  position: relative;

  .arrow-left {
    position: absolute;
    left: 50%;
    margin-left: -220px;
    top: 15px;
    z-index: 2;
    font-size: 40px;
    color: @white-color;
    font-weight: bold;
  }

  .arrow-right {
    position: absolute;
    left: 50%;
    margin-left: 180px;
    top: 15px;
    z-index: 2;
    font-size: 40px;
    color: @white-color;
    font-weight: bold;
  }

  .ant-picker-calendar {
    background-color: #40a3e6;
    font-size: 28px;
    color: @white-color;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: 80px;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    background-color: transparent;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    line-height: 1.5;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding: 10px 60px 10px 10px;
    margin: 0;
    border-top: none;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    line-height: 1.5;
    position: absolute;
    right: 15px;
    top: 5px;
  }

  .ant-picker-content th {
    color: @white-color;
  }

  .ant-picker-cell {
    padding: 3px 0;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }

  .ant-picker-cell-in-view {
    color: @white-color;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    border: #01619e solid 1px;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #ff8500;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background-color: #3195d9;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #ff8500;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #3195d9;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 140px;
    overflow: hidden;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    line-height: 50px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #40a3e6;
    border: none;
    border-radius: 6px;
    padding-left: 15px;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
    padding-right: 30px;
  }

  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0;
  }

  .ant-picker-calendar-header {
    justify-content: center;
    background-color: #01619e;
    padding: 20px 0;

    .ant-select-item {
      font-size: 28px;
      line-height: 1.5;
    }

    .ant-select {
      font-size: 30px;
      color: @white-color;
      opacity: 0;
    }

    .ant-select-arrow {
      top: 39%;
      color: @white-color;
      font-size: 22px;
      width: 24px;
    }
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ant-badge-status {
    display: flex;
  }

  .ant-badge-status-dot {
    top: 15px;
    width: 12px;
    height: 12px;
  }

  .ant-badge-status-text {
    font-size: 28px;
    color: @white-color;
    flex: 1;
  }

  .leader-events {
    background-color: #01619e;
    border-radius: 10px;
    height: 100%;
    padding: 15px 20px 15px 15px;
    position: relative;
    line-height: 1.3;

    .leader-events-scoll {
      overflow-y: auto;
      height: 100%;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #40a3e6;
        border-radius: 5px;
      }

      /*---滚动框背景样式--*/
      &::-webkit-scrollbar-track-piece {
        background-color: rgba(0, 234, 255, 0.12);
        width: 4px !important;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: rgba(0, 234, 255, 0.12);
      }
    }

    .leader-events-item {
      margin-bottom: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 15px;
      width: 8px;
      height: 114px;
      background-color: #198efb;
      border-radius: 8px;
      display: none;
    }
  }
}

.offline-swiper {
  .leadership-left-item {
    background-color: #94a0a8 !important;
  }

  .ss-bg {
    background-color: #818588 !important;
  }

  .ss-bg1 {
    background-color: #94a0a8 !important;
  }
}

.offline-slide {
  .leadership-left-item {
    background-color: #346c9b;
  }

  .ss-bg {
    background-color: #144b77;
  }

  .ss-bg1 {
    background-color: #346c9b;
  }
}

.draggable-box {
  .draggable-box-item {
    height: 162px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #40a3e6;
    border-radius: 5px;
  }

  /*---滚动框背景样式--*/
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 234, 255, 0.12);
    width: 4px !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(0, 234, 255, 0.12);
  }
}

.progress-color {
  color: #0ae1f6;
}

.calendar-ymd {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  top: 12px;
}

.leader-pargr {
  .ant-typography-ellipsis-single-line {
    overflow: scroll;
    text-overflow: clip;
  }
}